import ApplicationController from './application_controller';

export default class extends ApplicationController {
    static targets = ['customerManagedProductCostContainer', 'defaultCogsCurrency', 'customerManagedProductCostInput'];
    
    updatecustomerManagedProductCost() {
        let cogs_currency = this.defaultCogsCurrencyTarget.getAttribute('data-search-select-value');
        if(cogs_currency) {
            this.customerManagedProductCostContainerTarget.classList.remove('text-gray-400');
            this.customerManagedProductCostInputTarget.removeAttribute('disabled');
        } else {
            this.customerManagedProductCostContainerTarget.classList.add('text-gray-400');
            this.customerManagedProductCostInputTarget.setAttribute('disabled', true);
            this.customerManagedProductCostInputTarget.removeAttribute('checked');
        }
    }
}
