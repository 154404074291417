import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ['expand', 'toggleContainer', 'select', 'jetSelect', 'mappingtypeSelect', 'viewCategories', 'categoriesTurbo', 'submit', 'notRequiredCategory', 'requiredCategory'];

    initialize() {
        this.handleEvent = function (event) {
            if (event.type === 'searchselect:updated') {
                this.updateSelectHighlight(event.currentTarget, false, event);
            }
        };
    }

    categoriesLoaded() {
        if (this.hasSubmitTarget) {
            this.submitTarget.removeAttribute('disabled');
            this.submitTarget.classList.remove('form-disabled');
        }

        this.selectTargets.forEach((ele) => {
            this.updateSelectHighlight(ele, true);
        });

        this.selectTargets.forEach((ele) => {
            ele.addEventListener('searchselect:updated', this);
        });
    }

    mappingChanged() {
        // If the mapping is changed, then we need to reset the copy mapping selection
        if (this.hasJetSelectTarget && this.jetSelectTarget.dataset.searchSelectValue) {
            this.jetSelectTarget.dataset.searchSelectValue = '';
            let searchController = this.application.getControllerForElementAndIdentifier(this.jetSelectTarget, 'search-select');
            searchController.resetOptions();
            this.jetSelectionChanged();
        }
    }

    jetSelectionChanged() {
        if (!this.jetSelectTarget.dataset.searchSelectValue) {
            // Remove selected_jem param from form action
            let formUrl = new URL(this.element.action);
            formUrl.searchParams.delete('selected_jem');
            this.element.action = formUrl.toString();

            // Remove selected_jem and Add view param from categoriesTurboTarget
            let categoriesTurboUrl = new URL(this.categoriesTurboTarget.src);
            categoriesTurboUrl.searchParams.delete('selected_jem');
            categoriesTurboUrl.searchParams.set('view', this.viewCategoriesTarget.name);
            this.categoriesTurboTarget.src = categoriesTurboUrl.toString();
        } else {
            // Add selected_jem, view params to categoriesTurboTarget
            var categoriesTurboUrl = new URL(this.categoriesTurboTarget.src);
            categoriesTurboUrl.searchParams.set('view', this.viewCategoriesTarget.name);
            categoriesTurboUrl.searchParams.set('selected_jem', this.jetSelectTarget.dataset.searchSelectValue);
            
            // update categoriesTurboTarget only when the jet selection changed
            if(this.categoriesTurboTarget.src != categoriesTurboUrl) {
                this.categoriesTurboTarget.innerHTML = '';
                this.categoriesTurboTarget.setAttribute('data-busy', '');
                this.categoriesTurboTarget.src = categoriesTurboUrl;

                // Add selected_jem param to form action
                let formUrl = new URL(this.element.action);
                formUrl.searchParams.set('selected_jem', this.jetSelectTarget.dataset.searchSelectValue);
                this.element.action = formUrl.toString();
            }
        }
    }

    // Mapping type selection changed will be called when the mapping type is changed
    mappingtypeSelectionChanged() {
        if(this.element.dataset.dirty == 'true') {
            let res = confirm('Are you sure you want to change mapping type?  Your changes will not be saved.');
            if (!res) {
                let searchController = this.application.getControllerForElementAndIdentifier(this.mappingtypeSelectTarget, 'search-select');
                searchController.resetToPreviousOptions();
                return;
            }
        }
        // If the mapping type is changed, then we need to reset copy mapping selection
        this.mappingChanged();
        if (this.mappingtypeSelectTarget.dataset.searchSelectValue == 'account') {
            // If the mapping type is account, then the categoriesTurboTarget will be updated with the categoriesUrl of the mapping type dropdown
            var categoriesTurboUrl = new URL(this.mappingtypeSelectTarget.dataset.categoriesUrl);
        }
        else {
            // If the mapping type is not account, then the categoriesTurboTarget will be updated with the dimensionCategoriesUrl of the mapping type dropdown
            var categoriesTurboUrl = new URL(this.mappingtypeSelectTarget.dataset.dimensionCategoriesUrl);
        }
        // Set the view and mapping type param in the categoriesTurboUrl
        categoriesTurboUrl.searchParams.set('mapping_type', this.mappingtypeSelectTarget.dataset.searchSelectValue);
        categoriesTurboUrl.searchParams.set('view', this.viewCategoriesTarget.name);
        this.categoriesTurboTarget.src = categoriesTurboUrl;


        // Set the view and mapping type param in the form action url
        let formUrl = new URL(this.element.action);
        formUrl.searchParams.set('view', this.viewCategoriesTarget.name);
        formUrl.searchParams.set('mapping_type', this.mappingtypeSelectTarget.dataset.searchSelectValue);
        this.element.action = formUrl.toString();

        const event = new Event("change-tracker:mark-pristine");
        this.element.dispatchEvent(event);
    }

    updateSelectHighlight(ele, isConnect = false, event) {
        if (!ele.dataset.searchSelectValue) {
            ele.classList.remove('border-gray-700');
            ele.classList.add('border-bkblue', 'border-2');
            ele.classList.add('bg-opaque-blue');
            if (ele.closest('[data-subcat]') && (isConnect || event?.detail?.prev)) {
                this.updateExpandHighlight(ele, 1);
            }
        } else if (!isConnect) {
            ele.classList.remove('bg-opaque-blue');

            if (ele.closest('[data-subcat]') && !event?.detail?.prev) {
                this.updateExpandHighlight(ele, -1);
            }
        }
    }

    updateExpandHighlight(ele, count) {
        let category = ele.closest('[data-subcat]').dataset.subcat;
        let expandEle = document.querySelector(`[data-cat="${category}"]`);

        count = expandEle.dataset.unselected = expandEle.dataset.unselected ? +expandEle.dataset.unselected + count : 1;
        if (count > 0) {
            expandEle.classList.remove('hover:bg-gray-100', 'focus:bg-gray-100');
            expandEle.classList.add('bg-opaque-blue', 'hover:opacity-80');
        } else {
            expandEle.classList.add('hover:bg-gray-100', 'focus:bg-gray-100');
            expandEle.classList.remove('bg-opaque-blue', 'hover:opacity-80');
        }
    }

    validateList(e) {
        let requiredFields = this.element.querySelectorAll('[data-selection-required=true]');
        let unmapped = Array.from(requiredFields).find((x) => {
            return x.classList.value.includes('bg-opaque-blue');
        });
        if (unmapped) {
            let res = confirm(
                'Are you sure you want to save?  Your entries will not post to accounting until all required fields are mapped.'
            );
            if (!res) {
                e.preventDefault();
            }
        }
    }

    toggleSubcat(e) {
        e.preventDefault();

        let category = e.currentTarget.getAttribute('data-cat');
        let chevron = this.toggleContainerTarget.querySelector(`[data-cat="${category}"]`);
        if (chevron.querySelector('svg').classList.contains('rotate-chevron')) {
            chevron.querySelector('svg').classList.remove('rotate-chevron');
        } else {
            chevron.querySelector('svg').classList.add('rotate-chevron');
        }
    }

    subcategoriesLoaded(e) {
        const { target } = e;
        const subcategories = target.querySelectorAll('[data-controller="search-select"]');
        subcategories.forEach((ele) => {
            this.updateSelectHighlight(ele, true);
            ele.addEventListener('searchselect:updated', this);
        });
    }

    hideorShowCategories() {
        const url = new URL(window.location.href);
        const viewParam = url.searchParams.get('view');
        const updatedViewParam = viewParam === 'show_all_categories' ? 'show_required_categories' : 'show_all_categories';

        this.updateUrl(updatedViewParam, url);
        this.updateFormAction(url);

        this.notRequiredCategoryTargets.forEach((ele) => {
            ele.classList.toggle('hidden');
        });

        this.viewCategoriesTarget.name = updatedViewParam;
    }

    updateUrl(updatedViewParam, url) {
        url.searchParams.set('view', updatedViewParam);
        window.history.pushState('', '', url.toString());
    }

    updateFormAction(url) {
        let formUrl = new URL(this.element.action);
        formUrl.searchParams.set('view', url.searchParams.get('view'));
        this.element.action = formUrl.toString();
    }

    copyCategoryToSubCategories(e) {
        let categoryValue = document.querySelector(`[data-category-name=${e.currentTarget.dataset.subCategoriesCategoryName}]`).dataset.searchSelectValue;
        let subCategroyTurboFrame = document.querySelector(`turbo-frame#am-${e.currentTarget.dataset.subCategoriesCategoryName}-subcats`);
        // Show loader until data loads
        subCategroyTurboFrame.innerHTML = '';
        subCategroyTurboFrame.setAttribute('data-busy', '');

        let turboFrameUrl = new URL(subCategroyTurboFrame.src);
        turboFrameUrl.searchParams.set('copy_category_mapping', true);
        turboFrameUrl.searchParams.set('copy_category_value', categoryValue);
        // Adding timestamp as param to force reload the turbo-frame even though the src is same
        turboFrameUrl.searchParams.set('timestamp', Date.now());
        subCategroyTurboFrame.src = turboFrameUrl;

        const event = new Event("change-tracker:markDirty");
        this.element.dispatchEvent(event);
    }

    reloadJeMappingCard() {
        let jeMappingTurboFrame = document.querySelector(`#jem_mapping_config_card_view`);
        if(jeMappingTurboFrame){
                jeMappingTurboFrame.reload();
            }
    }
}
