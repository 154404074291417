import { useHotkeys } from 'stimulus-use';
import ApplicationController from './application_controller';

export default class extends ApplicationController {
    static targets = ['submit', 'formControlsSection', 'confirmInput', 'testLabel', 'test', 'control'];

    static values = {
        hasRecaptcha: {
            type: Boolean,
            default: false,
        },
        checkRequiredOnConnect: {
            type: Boolean,
            default: false,
        },
        noConfirmationOnLeave: {
            type: Boolean,
            default: false,
        },
        frameToRefresh: String,
    };

    declare hasRecaptchaValue: boolean;
    declare checkRequiredOnConnectValue: boolean;
    declare noConfirmationOnLeaveValue: boolean;
    declare frameToRefreshValue: string;
    declare hasTestTarget;
    declare testTarget;
    declare hasTestLabelTarget;
    declare hasControlTarget;
    declare testLabelTarget;
    declare controlTargets;
    declare submitTarget;
    declare hasSubmitTarget;
    declare hasFormControlsSectionTarget;
    declare formControlsSectionTarget;
    declare hasConfirmInputTarget;
    declare confirmInputTarget;

    get element(): HTMLFormElement {
        return super.element as HTMLFormElement;
    }

    connect() {
        super.connect();
        if (this.hasTestTarget) {
            useHotkeys(this, {
                'ctrl+shift+l': [this.toggleTest],
            });
        }
        if (this.checkRequiredOnConnectValue) {
            setTimeout(() => this.checkRequiredFields(), 0);
        }
        this.element.addEventListener('form:check-required-fields', () => {
            this.checkRequiredFields();
        });
    }

    toggleTest() {
        if (this.hasTestTarget) {
            let setTest = false;
            if (!this.testTarget.value || this.testTarget.value == 'false') {
                setTest = true;
            }
            this.testTarget.value = setTest;
            if (this.hasTestLabelTarget) {
                if (setTest) {
                    this.testLabelTarget.classList.remove('hidden');
                } else {
                    this.testLabelTarget.classList.add('hidden');
                }
            }
        }
    }

    checkRequiredFields() {
        let inValid = false;
        if (this.hasControlTarget) {
            const requiredFields = this.controlTargets.filter(
                (target) => target.required == true || target.dataset.required == 'true'
            );
            requiredFields.forEach((x) => {
                if (x.type == 'text' || x.type == 'textarea') {
                    if (!x.value.trim().length) {
                        inValid = true;
                    }
                } else if (x.type == 'file') {
                    if (x.files.length == 0 && x.required) {
                        inValid = true;
                    }
                }
                if (inValid) {
                    return;
                }
                if (
                    (!(x.dataset.required === 'true') && !x.checkValidity()) ||
                    x.isValid === false ||
                    x.isPending === true
                ) {
                    inValid = true;
                }
            });
        }
        if (inValid) {
            this.submitTarget.setAttribute('disabled', '');
            this.submitTarget.classList.add('form-disabled');
            if (this.noConfirmationOnLeaveValue) {
                this.sendEvent(this.element, 'change-tracker:mark-pristine');
            }
        } else if (this.element.dataset.dirty || this.element.dataset.dirty == undefined) {
            this.submitTarget.removeAttribute('disabled');
            this.submitTarget.classList.remove('form-disabled');
        }
    }

    submit(e) {
        if (e.confirmCancelled) {
            return;
        }
        let prevented = false;
        if (this.hasControlTarget) {
            this.controlTargets.forEach((x) => {
                this.sendEvent(x, 'form:submit');
                if (x.isValid === false) {
                    this.shake(x);
                    e.preventDefault();
                    prevented = true;
                    e.returnValue = false;
                }
            });
        }
        if (prevented) return;
        if (this.hasSubmitTarget) {
            this.submitTarget.disabled = true;
            this.submitTarget.classList.add('processing');
        }

        if (this.hasRecaptchaValue) {
            e.preventDefault();
            (window as any).grecaptcha.execute();
        }
    }

    revealControls(e) {
        if (this.hasFormControlsSectionTarget) {
            if (this.formControlsSectionTarget.classList.value.indexOf('hidden') > -1) {
                e.preventDefault();
                this.formControlsSectionTarget.classList.remove('hidden');
                const el = this.formControlsSectionTarget.querySelector('input[type="text"]');
                el && el.focus();
            }
        }
    }

    hideControls(e) {
        if (this.hasFormControlsSectionTarget) {
            e.preventDefault();
            this.formControlsSectionTarget.classList.add('hidden');
        }
    }

    autosubmit() {
        this.submitTarget.click();
    }

    confirmChange() {
        if (this.hasConfirmInputTarget) {
            const completeWith = this.data.get('complete-with');
            if (this.confirmInputTarget.value.toLowerCase() == completeWith.toLowerCase()) {
                this.confirmInputTarget.isValid = true;
                this.confirmInputTarget.classList.add('shadow-green');
                this.confirmInputTarget.classList.remove('shadow-red');
            } else {
                this.confirmInputTarget.isValid = false;
                this.confirmInputTarget.classList.add('shadow-red');
                this.confirmInputTarget.classList.remove('shadow-green');
            }
            this.checkRequiredFields();
        }
    }

    complete(e) {
        if (this.hasSubmitTarget) {
            this.submitTarget.disabled = false;
            this.submitTarget.classList.remove('processing');
        }
        let message = e.detail.fetchResponse.response.headers.get('x-alert');
        const type = e.detail.fetchResponse.response.headers.get('x-alert-type');
        if (!message) {
            if (e.detail.success) {
                message = this.data.get('success') || 'Saved successfully!';
                this.showAlert(message, type || 'success');
            } else {
                message = this.data.get('error') || 'Something went wrong!';
                this.showAlert(message, { type: 'error' });
            }
        }

        if (this.data.get('closeOnComplete')) {
            this.element.classList.add('hidden');
        }

        if (this.submitTarget.dataset.disableButtonOnSuccess && e.detail.success) {
            this.submitTarget.setAttribute('disabled', '');
            this.submitTarget.classList.add('form-disabled');
        }
        if (this.hasFormControlsSectionTarget && !this.data.get('closeControlsOnComplete')) {
            this.formControlsSectionTarget.classList.add('hidden');
            this.formControlsSectionTarget.querySelectorAll('input[type="text"]').forEach((x) => (x.value = ''));
        }
    }

    recaptchaCallback() {
        this.element.submit();
    }

    reset() {
        this.element.reset();
    }

    refreshFrame() {
        if (this.frameToRefreshValue) {
            const element = document.querySelector(`#${this.frameToRefreshValue}_src`) as HTMLFormElement;
            element && element.click();
        }
    }
}
